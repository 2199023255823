import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Layout } from "../components"

const page404 = () => {
  return (
    <Layout>
      <Seo title="Koomet - Erreur 404" />
      <div className="pt-16 pb-12 xl:pb-20 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-violet uppercase tracking-wide">Erreur 404</p>
              <h1 className="mt-2 text-4xl font-extrabold text-gris dark:text-white tracking-tight sm:text-5xl">Page non trouvée.</h1>
              <p className="mt-2 text-base text-gray-500 dark:text-gray-100">Nous sommes désolés, mais cette page n'existe pas.</p>
              <div className="mt-6">
                <AniLink cover bg="#6377E9" direction="up" duration={0.6} className="text-base font-medium text-violet" to="/">
                  Revenir à l'accueil <span aria-hidden="true"> &rarr;</span>
                </AniLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default page404
